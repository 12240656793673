import {
  createTheme,
  // responsiveFontSizes,
} from '@material-ui/core';
import {
  orange,
  // amber,
  // green,
  // purple,
} from '@material-ui/core/colors';

require('../assets/prism-xonokai-mymod.css');
// require('prismjs/plugins/normalize-whitespace/prism-normalize-whitespace');

// theme = responsiveFontSizes(theme);
// https://material-ui.com/customization/theming/
const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
  palette: {
    type: 'dark',
    primary: {
      main: orange[600],
      // main: amber[800],
      // main: green.A700,
      // main: purple[400],
    },
    text: {
      primary: '#cdcdcd',
      secondary: '#6c757d',
    },
    background: {
      default: 'rgba(12, 13, 15, 0.9)',
      paper: 'rgba(18, 19, 21, 0.9)',
      solid: 'rgba(18, 19, 21, 1)',
      lighter: 'rgba(58, 60, 69, 0.2)',
    },
  },
  shape: {
    borderRadius: 6,
  },
  custom: {
    blockquote: {
      // text: '#ececec',
      // background: 'rgba(23, 25, 27, 0.9)',
      // border: '#747474',
      // borderLeft: '#848484',
      // quoteSign: '#afafaf',
      text: '#bebec5',
      background: 'rgba(8, 8, 8, 0.8)',
      border: '#555555',
      borderLeft: '#6c6c6c',
      quoteSign: '#969798',
    },
    shadows: {
      10: '0 0 10px 2px black',
      20: '0 0 20px 10px black',
    },
  },
});

theme.props = {
  MuiButton: {
    // disableElevation: true,
    // disableFocusRipple: true,
  },
};

theme.overrides = {
  // MuiCssBaseline: {
  //   '@global': {
  //     body: {
  //       // '@font-face': ['Montserrat'],
  //       fontSize: '1rem',
  //       fontFamily: [ // nie działa :(
  //         'Montserrat',
  //         // 'Roboto',
  //         'sans-serif',
  //       ].join(','),
  //     },
  //   },
  // },
  MuiTypography: {
    h1: {
      fontSize: '3.3rem',
      lineHeight: 1.4,
      fontWeight: 300,
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },
    h2: {
      fontSize: '2.8rem',
      fontWeight: 300,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.8rem',
      },
    },
    h3: {
      fontSize: '2.3rem',
      fontWeight: 300,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.65rem',
      },
    },
    h4: {
      fontSize: '1.9rem',
      fontWeight: 300,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.35rem',
      },
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 400,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.63,
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',  // disable uppercase transformation
      '&:hover': {
        backgroundColor: 'transparent',  // disable grey hover backgroudColor
        color: theme.palette.primary.main,  // to cover button icons
        backgroundPosition: '0%',
        transition: 'all 0.3s ease, text-shadow 0.5s ease-in',
        // textShadow: `0px 0px 1px ${theme.palette.primary.main}`,
        // left: 1,
        // top: -1,
      },
      transition: 'all 0.5s ease, text-shadow 0.3s ease-out',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundImage: `linear-gradient(
        to right,
        ${theme.palette.primary.main},
        ${theme.palette.primary.main} 50%,
        ${theme.palette.text.primary} 50%)
      `,
      backgroundSize: '200% 100%',
      backgroundPosition: '100%',
      fontSize: '1rem',
    },
  },
  MuiDrawer: {
    paper: {
      backgroundColor: theme.palette.background.solid,
    },
  },
};

export default theme;
// export default responsiveFontSizes(theme);
